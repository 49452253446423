import React from "react"
import { Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const ImageButton = ({ button, className = null }) => {
    if (button.blank) {
        return <a href={button.url} className={className} target="_blank" rel="noreferrer">
            <GatsbyImage alt={button.image.name} image={getImage(button.image.localFile)} />
        </a>
    }

    return <Link to={button.url} className={className}>
        <GatsbyImage alt={button.image.name} image={getImage(button.image.localFile)} />
    </Link>
}

export default ImageButton
